<template>
  <app-layout>
    <page-header> Modifica produs </page-header>

    <page-section>
      <loader v-if="loading" />
      <product-form
        v-else
        :product="product"
        :service="productsApi"
        method="update"
        :extra="{ product: product.id }"
        @created:product="redirect()"
      ></product-form>
    </page-section>
  </app-layout>
</template>

<script>
import AppLayout from "../layout/AppLayout.vue";
import productsApi from "../api/resources/productsApi";
import Loader from "../components/Loader.vue";
import ProductForm from "../components/shared/ProductForm.vue";
import PageHeader from "../components/PageHeader.vue";
import PageSection from '../components/PageSection.vue';

export default {
  components: {
    AppLayout,
    ProductForm,
    Loader,
    PageHeader,
    PageSection,
  },

  props: ["id"],

  data() {
    return {
      product: {},
      loading: true,
      productsApi: productsApi,
    };
  },

  methods: {
    redirect() {
      this.$router.push({ name: "products.index" });
    },
  },

  created() {
    productsApi.show(this.id).then((response) => {
      this.product = response.data;
      this.loading = false;
    });
  },
};
</script>

<style></style>
