<template>
  <!-- <header class="p-4 flex justify-between"> -->
  <header class="lg:flex lg:items-center lg:justify-between p-4">
    <div class="min-w-0">
      <h1 class="text-3xl font-bold text-gray-900">
        <slot />
      </h1>
      <v-breadcrumbs class="mt-1" />
    </div>
    <div class="mt-2 flex lg:mt-0 lg:ml-4">
      <slot name="end" />
    </div>
  </header>
</template>

<script>
import VBreadcrumbs from "./VBreadcrumbs.vue";
export default {
  components: { VBreadcrumbs },
};
</script>
